<template>
  <div id="filter">
    <el-dialog
      :title="filterFormTitle"
      width="680px"
      :visible.sync="filterDialogVisible"
      :close-on-click-modal="false"
      @close="filterDialogClose"
    >
      <el-form
        ref="filterFormRef"
        :model="filterForm"
        :rules="filterFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceId">
              <el-select v-model="filterForm.deviceId" placeholder="请选择设备" clearable>
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="过滤器房间" prop="filterRoom">
              <el-input v-model="filterForm.filterRoom" placeholder="请输入过滤器房间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="过滤器位置" prop="filterPosition">
              <el-input v-model="filterForm.filterPosition" placeholder="请输入过滤器位置" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="捡漏结果" prop="checkResult">
              <el-input v-model="filterForm.checkResult" placeholder="请输入捡漏结果" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" prop="operator">
              <el-input v-model="filterForm.operator" placeholder="请输入操作人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作时间" prop="operatingTime">
              <el-date-picker v-model="filterForm.operatingTime" placeholder="请选择操作时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="filterForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="filterDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="filterFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="filterPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="filterRoom" label="过滤器房间" />
      <el-table-column prop="filterPosition" label="过滤器位置" />
      <el-table-column prop="checkResult" label="捡漏结果" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="操作时间">
        <template slot-scope="scope">
          <span v-if="scope.row.operatingTime">{{ scope.row.operatingTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="filterPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addFilter, deleteFilter, updateFilter, selectFilterInfo, selectFilterList } from '@/api/device/filter'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      filterDialogVisible: false,
      filterFormTitle: '',
      filterForm: {
        id: '',
        deviceId: '',
        filterRoom: '',
        filterPosition: '',
        checkResult: '',
        operator: '',
        operatingTime: '',
        remarks: ''
      },
      filterFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}],
        filterRoom: [{ required: true, message: '过滤器房间不能为空', trigger: ['blur', 'change']}],
        filterPosition: [{ required: true, message: '过滤器位置不能为空', trigger: ['blur', 'change']}],
        checkResult: [{ required: true, message: '捡漏结果不能为空', trigger: ['blur', 'change']}]
      },
      filterPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      },
      deviceList: []
    }
  },
  created () {
    selectBaseInfoList().then(res => {
      this.deviceList = res.list
    })
    selectFilterList(this.searchForm).then(res => {
      this.filterPage = res
    })
  },
  methods: {
    filterDialogClose () {
      this.$refs.filterFormRef.resetFields()
    },
    filterFormSubmit () {
      if (this.filterFormTitle === '高效过滤器检漏记录详情') {
        this.filterDialogVisible = false
        return
      }
      this.$refs.filterFormRef.validate(async valid => {
        if (valid) {
          if (this.filterFormTitle === '新增高效过滤器检漏记录') {
            await addFilter(this.filterForm)
          } else if (this.filterFormTitle === '修改高效过滤器检漏记录') {
            await updateFilter(this.filterForm)
          }
          this.filterPage = await selectFilterList(this.searchForm)
          this.filterDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.filterFormTitle = '新增高效过滤器检漏记录'
      this.filterDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFilter(row.id)
        if (this.filterPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.filterPage = await selectFilterList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.filterFormTitle = '修改高效过滤器检漏记录'
      this.filterDialogVisible = true
      this.selectFilterInfoById(row.id)
    },
    handleInfo (index, row) {
      this.filterFormTitle = '高效过滤器检漏记录详情'
      this.filterDialogVisible = true
      this.selectFilterInfoById(row.id)
    },
    selectFilterInfoById (id) {
      selectFilterInfo(id).then(res => {
        this.filterForm.id = res.id
        this.filterForm.deviceId = res.deviceId
        this.filterForm.filterRoom = res.filterRoom
        this.filterForm.filterPosition = res.filterPosition
        this.filterForm.checkResult = res.checkResult
        this.filterForm.operator = res.operator
        this.filterForm.operatingTime = res.operatingTime
        this.filterForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFilterList(this.searchForm).then(res => {
        this.filterPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFilterList(this.searchForm).then(res => {
        this.filterPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFilterList(this.searchForm).then(res => {
        this.filterPage = res
      })
    }
  }
}
</script>

<style>
</style>
