import axios from '@/common/axios'
import qs from 'qs'

export function addFilter (data) {
  return axios({
    method: 'post',
    url: '/device/filter/add',
    data: qs.stringify(data)
  })
}

export function deleteFilter (id) {
  return axios({
    method: 'delete',
    url: '/device/filter/delete/' + id
  })
}

export function updateFilter (data) {
  return axios({
    method: 'put',
    url: '/device/filter/update',
    data: qs.stringify(data)
  })
}

export function selectFilterInfo (id) {
  return axios({
    method: 'get',
    url: '/device/filter/info/' + id
  })
}

export function selectFilterList (query) {
  return axios({
    method: 'get',
    url: '/device/filter/list',
    params: query
  })
}
